
import {
  VNode,
} from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { Image, IPlugin, VueImagePickerPlugin } from './types';

@Component({
  components: {
    Renderer: {
      props: ['vnode'],
      render(): VNode {
        return (this as any).vnode;
      },
    },
  },
})
export default class ImagePicker extends Vue {
  private plugins: IPlugin[] = [];

  private active = 0;

  private get nodes() {
    const plugins = this.plugins.map((p) => p.node);
    return plugins;
  }

  private get activeInstance() {
    return this.getInstance(this.plugins[this.active].node);
  }

  public getValues(): Image[] {
    if (!this.activeInstance.getValues) {
      return [];
    }

    const values = this.activeInstance.getValues();
    return values;
  }

  public switch(tab: string | number) {
    this.show(typeof tab === 'string' ? this.plugins.findIndex((el) => el.name === tab) : tab);
  }

  private getInstance(node: VNode): VueImagePickerPlugin {
    return (node.componentInstance as unknown) as VueImagePickerPlugin;
  }

  private show(idx: number) {
    if (idx < 0 || idx >= this.plugins.length || idx === this.active) {
      return;
    }

    this.getInstance(this.nodes[idx]).showed = true;
    this.getInstance(this.nodes[this.active]).showed = false;
    this.active = idx;
  }

  private changeProp(node: VNode, name: string, value: any) {
    // eslint-disable-next-line no-param-reassign
    (node!.componentOptions!.propsData! as { [key: string]: string })[name] = value;
  }

  created() {
    const nodes = this.$slots.default;

    this.plugins = nodes
      ? nodes.map((p) => {
        this.changeProp(p, 'showed', false);
        const { name, text, disabled } = p.data!.attrs!;
        const plugin: IPlugin = {
          name,
          text,
          disabled,
          node: p,
        };
        return plugin;
      })
      : [];

    if (!this.plugins[this.active].disabled) {
      return;
    }
    const idx = this.plugins.findIndex((el) => !el.disabled);
    if (idx !== -1) {
      this.$nextTick(() => {
        this.show(idx);
      });
    }
  }
}
